:root {
    --toastify-color-success: #00b09a;
    --toastify-color-error: #e4573d;
    --toastify-toast-width: 25%;
    --toastify-font-family: 'Roboto', sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html,
    body,
    div#root {
        @apply h-screen min-h-screen;
        font-family: 'Roboto', sans-serif;
        font-size: 1.08rem;
    }

    body {
        background-color: #f5f5f5;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    th {
        font-weight: 500;
        font-family: 'Oswald', sans-serif;
    }

    h1 {
        @apply text-5xl;
        line-height: 3.5rem !important;
    }
    h2 {
        @apply text-4xl;
        line-height: 3rem !important;
    }
    h3 {
        @apply text-3xl;
        line-height: 2.5rem !important;
    }
    h4 {
        @apply text-2xl;
    }
    h5 {
        @apply text-xl;
    }
    h6 {
        @apply text-base;
    }

    @media (min-width: 768px) {
        h1 {
            @apply text-6xl;
        }
        h2 {
            @apply text-5xl;
        }
        h3 {
            @apply text-4xl;
        }
        h4 {
            @apply text-3xl;
        }
        h5 {
            @apply text-2xl;
        }
        h6 {
            @apply text-xl;
        }
    }
}

@layer components {
    .button {
        @apply bg-gray-200 uppercase font-bold px-8 py-4 flex justify-center items-center;
    }

    .button.small {
        @apply px-4 py-2;
    }

    .button.red {
        @apply bg-bioetica-red text-white;
    }

    .button.green {
        @apply bg-green-500 text-white;
    }

    .button:disabled {
        @apply bg-gray-200 text-bioetica-red cursor-not-allowed;
    }

    .input {
        @apply w-full rounded bg-gray-200 px-6 py-4 text-xl;
    }

    .input.small {
        @apply px-3 py-1;
    }

    .input-error {
        @apply bg-bioetica-red px-2 py-1 text-white text-sm font-bold italic;
    }

    .description-box {
        height: 300px;
        overflow: hidden;
        position: relative;
    }

    .description-box .toggle {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(to bottom, transparent, #f5f5f5 90%);
    }

    .description-box.open {
        height: auto;
    }

    .description-box.open .toggle {
        height: auto;
        position: initial;
    }
}
